import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TravelerService } from '../../services/traveler.service';
import { ItineraryService } from '../../services/itinerary.service';
import { UtilityService } from '../../services/utility.service';
import { HttpClient } from '@angular/common/http';
import { ServerDataSource } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-report-itinerary-departures-return',
  templateUrl: './report-itinerary-departures-return.component.html',
  styleUrls: ['./report-itinerary-departures-return.component.css']
})
export class ReportItineraryDeparturesReturnComponent implements OnInit {
  gridColumn = [];
  model: any = {};
  settings: any;
  mySettings: any;
  data: any = {};
  response: any = {};
  yearList: any = [];
  flightTypeList: any = [];
  tripOptionList = [];
  alltripList = [];
  tripId = '';
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  years = new Date().getFullYear();
  isReturn: number;
  IsReturn: number;
  constructor(
    private travelerService: TravelerService,
    private _utilityService: UtilityService,
    private http: HttpClient,
    private itineraryService: ItineraryService,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.mySettings = {
      columns: {},
      actions: {
        edit: false,
        add: false,
        delete: false,
        position: 'right',
      },
      noDataMessage: 'No record found.',
      pager: {
        display: true,
        perPage: 25,
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },
    };
    this.settings = Object.assign({}, this.mySettings);
    this.travelerHeader(this.years, this.model.IsReturn);
    // this.travelerList(this.years);
  }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.flightTypeList = this._utilityService.getFlightType();
    this.model.Year = this.years;
    this.model.IsReturn = 0;
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
      if (this.alltripList && this.alltripList.length > 0)
        this.tripOptionList = this.alltripList.filter(
          (t) => t.years == this.years
        );
    });
    //this.customerStatementList(this.years);
  }
  travelerList(year, isReturn) {
    if (+this.tripId > 0) {
      this.data = new ServerDataSource(this.http, {
        endPoint:
          this.baseUrl +
          'api/Itinerary/ReportItineraryDepartureReturn?year=' +
          year +
          '&tripId=' +
          this.tripId +
          '&isReturn=' +
          isReturn,
        dataKey: 'data.data',
        totalKey: 'totalCount',
        pagerLimitKey: 'recordCount',
        pagerPageKey: 'pageNumber',
        filterFieldKey: '#field#',
        sortFieldKey: 'sortColumn',
        sortDirKey: 'sortOrder',
      });

    } else {
      this.data = [];
    }
    //console.log(this.data);
  }
  bindTravelerList() {
    this.tripId = '';
    this.tripOptionList = [];
    this.data = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years == this.model.Year
      );
    this.travelerHeader(this.model.Year, this.model.IsReturn);
  }

  travelerHeader(year, isReturn) {
    //console.log(isReturn,year);
    // isReturn = Number(+isReturn);
    // console.log('2'+' '+ +isReturn);
    // this.model.IsReturn = +this.model.IsReturn;
    // console.log('3'+' '+ +this.model.IsReturn);
    // if(isReturn == 'undefined' && this.model.IsReturn == 'undefined'){
    //   isReturn = 0;
    //   this.model.IsReturn = 0;
    // }
    this.itineraryService
      .getMultipleItineraryHeader(year, +this.tripId, +this.model.IsReturn)
      .subscribe((result) => {
        // console.log(result);
        this.gridColumn = result;
        //console.log(this.gridColumn);
        this.mySettings.columns = {};
        for (let i = 0; i < this.gridColumn.length; i++) {
          this.mySettings.columns[this.gridColumn[i].columnName] = {
            title: this.gridColumn[i].title,
            sort: false,
            filter: this.gridColumn[i].filter,
          };
        }
        this.mySettings.pager = {
          display: true,
          perPage: 25,
        };
        this.mySettings.attr = {
          class: 'table table color-table dark-table table-striped',
        };
        this.mySettings.attr = {
          class: 'table table color-table dark-table table-striped',
        };

        this.mySettings.actions = {
          edit: false,
          add: false,
          delete: false,
          position: 'right',
        };
        this.settings = Object.assign({}, this.mySettings);
        this.travelerList(this.model.Year, isReturn);
      });
  }

  exportTraveler() {
    let filterData = this.data.getFilter();
    let extraSearch = '';
    if (filterData.filters.length > 0) {
      filterData.filters.forEach((element) => {
        extraSearch += '&' + element.field + '=' + element.search;
      });
    }
    window.open(
      this.baseUrl +
      'api/Itinerary/GetReportItineraryDepartureReturnExport?year=' +
      this.model.Year +
      '&tripId=' +
      this.tripId +
      '&isReturn=' +
      this.model.IsReturn +
      extraSearch
    );
  }

  changeTrip() {
    this.data = [];
    this.travelerHeader(this.model.Year, this.model.IsReturn);
  }
}

