import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportLogService } from 'src/app/services/report-log.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-log-traveler-hotel-update',
  templateUrl: './report-log-traveler-hotel-update.component.html',
  styleUrls: ['./report-log-traveler-hotel-update.component.css'],
})
export class ReportLogTravelerHotelUpdateComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = '';
  tripId = '';
  searchText = '';

  public displayedColumns = [
    //'updatedOnDisplay',
    'formattedDate',
    'formattedTime',
    'updatedByName',
    'tripCode',
    'ppeWhsCode',
    'salesmanCode',
    'customerNumber',
    'companyName',
    'groups',
    'legalName',
    'gender',
    'nickName',
    'dateofBirthDisplay',
    'cellPhone',
    'emailAddress',
    'dhCheckInDateDisplay',
    'dhCheckInDateOldDisplay',
    'dhCheckOutDateDisplay',
    'dhCheckOutDateOldDisplay',
    'roomType',
    'roomTypeOld',
    'dhCost',
    'dhCostOld',
  ];

  public dataSource = new MatTableDataSource<TravelerHotelUpdateEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updatedHotelList() {
    this.dataSource.data = [];
    if (+this.tripId > 0 && +this.years > 0) {
      this.reportLogService
        .getTravelerHotelUpdateList(this.years, this.tripId)
        .subscribe((result) => {
          this.model = this.dataSource.data =
            result as TravelerHotelUpdateEntity[];
          this.dataSource.paginator = this.paginator;
        });
    }
  }

  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
  }

  changeTrip() {
    this.updatedHotelList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface TravelerHotelUpdateEntity {
  id: number;
  updatedOnDisplay: string;
  updatedByName: string;
  tripCode: string;
  ppeWhsCode: string;
  salesmanCode: string;
  customerNumber: string;
  companyName: string;
  groups: number;
  legalName: string;
  gender: number;
  nickName: string;
  dateofBirthDisplay: string;
  cellPhone: string;
  emailAddress: string;
  dhCheckInDateDisplay: string;
  dhCheckInDateOldDisplay: string;
  dhCheckOutDateDisplay: string;
  dhCheckOutDateOldDisplay: string;
  roomType: string;
  roomTypeOld: string;
  dhCost: number;
  dhCostOld: number;
  formattedDate:string;
  formattedTime:string;
}
