import { Component, OnInit, Renderer2 } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ReportLogService } from "src/app/services/report-log.service";
import * as XLSX from "xlsx";
import { TravelerService } from "src/app/services/traveler.service";
import { UtilityService } from "src/app/services/utility.service";
import { LoaderService } from "src/app/services/loader.service";
@Component({
  selector: "app-report-nightly-count",
  templateUrl: "./report-nightly-count.component.html",
  styleUrls: ["./report-nightly-count.component.css"],
})
export class ReportNightlyCountComponent implements OnInit {
  selectButton = false;
  getReportNightlyArray: any[];
  getKeys: any = [];
  getReportNightlyArrays: any[];
  getKeyss: any = [];
  model: any = [];
  selectedDate: string;
  years: any;
  year = "";
  searchForm: FormGroup;
  roomSlots: any[] = [];
  updatedRoomSlot: any;
  tripList: any;
  roomAvailability: [];
  originalRoomAvailability: [];
  dataSource: any;
  roomAvailabilities: any;
  keysOfRoomAvailability: any;
  dateFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
  isDateGreaterThanOneMonth: boolean = false;
  dataLoaded: boolean = false;

  loggedUser: any;
  constructor(
    private fb: FormBuilder,
    private _reportLogService: ReportLogService,
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    public loaderService: LoaderService
  ) { }
  ngOnInit() {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.loadPage();
  }
  loadPage() {
    const currentYear = new Date().getFullYear();
    this.bindTrip(currentYear);
    this.searchForm = this.fb.group(
      {
        year: [currentYear, Validators.required],
        trip: ["", Validators.required],
        fromDate: ["", Validators.required],
        toDate: ["", Validators.required],
      },
      { validator: this.dateRangeValidator }
    );
  }

  bindTrip(year: number) {
    this.loaderService.hide();
    this.travelerService.getTripCodeList().subscribe((result) => {
      if (result) {
        //console.log("bindTrip result", result)
        this.years = [...new Set(result.map((item) => item.years))].sort(
          (a: number, b: number) => b - a
        );
        result = result.filter((item) => item.years === year);
        this.tripList = result;
      }
    });
  }

  dateRangeValidator(group: FormGroup): { [key: string]: boolean } | null {
    const startDate = group.get("fromDate").value;
    const endDate = group.get("toDate").value;
    if (startDate && endDate) {
      const diffInMs = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      if (diffInDays > 90) {
        return { dateRangeInvalid: true };
      }
    }
    return null;
  }

  isDateRangeValid(): boolean {
    const startDate = this.searchForm.get("fromDate").value;
    const endDate = this.searchForm.get("toDate").value;
    if (!startDate || !endDate) {
      return true;
    }
    return endDate >= startDate;
  }

  onSearch() {
    if (this.searchForm.valid) {
      const startDate = this.searchForm.value.fromDate;
      const endDate = this.searchForm.value.toDate;
      if (startDate && endDate) {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const startMonth = startDateObj.getMonth() + 1;
        const endMonth = endDateObj.getMonth() + 1;
        this.isDateGreaterThanOneMonth = startMonth !== endMonth;
      }
      this.nightlyRoomAvailability();
      this.getNightlyCount();
      this.dataLoaded = true;
      this.selectButton = true;
    } else {
      this.dataLoaded = false;
      this.selectButton = false;
      this.roomAvailabilities = [];
      this.getReportNightlyArrays = [];
    }
  }

  nightlyRoomAvailability() {
    let payload = {
      year: this.searchForm.value.year,
      tripId: this.searchForm.value.trip,
      startDate: this.searchForm.value.fromDate
        .toLocaleDateString("en-GB", this.dateFormatOptions)
        .replace(",", "")
        .replace(/ /g, "-"),
      endDate: this.searchForm.value.toDate
        .toLocaleDateString("en-GB", this.dateFormatOptions)
        .replace(",", "")
        .replace(/ /g, "-"),
    };
    this._reportLogService.nightlyRoomAvailability(payload).subscribe(
      (result) => {
        if (result) {
          this.originalRoomAvailability = JSON.parse(result.data);
          this.roomAvailability = JSON.parse(result.data);
          const transformData = this.transformToKeyValuePairs(
            this.roomAvailability
          );
          this.keysOfRoomAvailability = Object.keys(transformData[0]).filter(
            (key) => key !== "RoomType"
          );

          this.roomAvailabilities = transformData.map((d) => {
            return {
              ...d,
              editFieldName: "",
            };
          });
          //  console.log("roomAvailabilities", this.roomAvailabilities);
        }
      }
    );
  }

  transformToKeyValuePairs(data: any[]): TransformedItem[] {
    const result: TransformedItem[] = [];
    data.forEach((item) => {
      const roomType = item.RoomType;
      const roomAvailability = item.RoomAvailability;
      const transformedItem: TransformedItem = {
        RoomType: roomType,
      };
      Object.keys(roomAvailability).forEach((date) => {
        transformedItem[date] = {
          slot: roomAvailability[date].currentValue,
          originalValue: roomAvailability[date].originalValue,
          isEditable: roomAvailability[date].isUpdate,
          orginalTooltipText: "Original Value",
          currentTooltipText: roomAvailability[date].updatedAt,
          updatedBy: roomAvailability[date].updatedBy
        };
      });
      result.push(transformedItem);
    });
    return result;
  }

  onChangeYear() {
    const selectedYear = this.searchForm.get("year").value;
    this.bindTrip(selectedYear);
    this.searchForm.patchValue({ trip: '' });
    this.selectButton = false;
  }

  onChangeTrip() {
    const selectedTripId = this.searchForm.get("trip").value;
    this.getGroupCheckInCheckoutDateByTripId(selectedTripId);
    this.selectButton = false;
  }

  getGroupCheckInCheckoutDateByTripId(tripId: number) {
    this._reportLogService
      .groupCheckInCheckoutDateByTripId(tripId)
      .subscribe((result) => {
        if (result.data) {
          this.searchForm.patchValue({
            fromDate: new Date(result.data["minCheckInDate"]),
            toDate: new Date(result.data["maxCheckOutDate"]),
          });
        }
      });
  }

  exportModifiedNightlyReport() {
    const tirip = this.tripList.find(
      (item) => item.id === this.searchForm.get("trip").value
    );
    const startDate = this.searchForm.get("fromDate").value;
    const endDate = this.searchForm.get("toDate").value;
    const formattedStartDate = this.formatDate(startDate, "underscore");
    const formattedEndDate = this.formatDate(endDate, "underscore");

    const fileName = `Modified NightlyRoomCount-${tirip["tripCode"]}_${formattedStartDate} - ${formattedEndDate}`;
    //let sheetName = `${tirip["tripCode"]}-${formattedStartDate}-${formattedEndDate}`;
    let sheetName = `${tirip["tripCode"]}`;
    // if (sheetName.length > 31) {
    //   sheetName = sheetName.substring(0, 31);
    // }

    const exportData = this.roomAvailabilities.map((item) => {
      delete item.editFieldName;

      const transformedItem: any = {
        RoomType: item.RoomType,
      };
      Object.keys(item).forEach((key) => {
        if (key === "RoomType") {
          transformedItem[key] = item[key];
        } else if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
          const newKey = this.formatDate(key, "forwardSlash");
          transformedItem[newKey] = item[key].slot;
        }
      });

      return transformedItem;
    });
    this.exportAsExcelFile(exportData, tirip["tripCode"], fileName, this.formatDate(startDate, "forwardSlash"), this.formatDate(endDate, "forwardSlash"), sheetName);
  }

  exportOriginalNightlyReport() {
    const tirip = this.tripList.find(
      (item) => item.id === this.searchForm.get("trip").value
    );
    const startDate = this.searchForm.get("fromDate").value;
    const endDate = this.searchForm.get("toDate").value;

    const formattedStartDate = this.formatDate(startDate, "underscore");
    const formattedEndDate = this.formatDate(endDate, "underscore");

    const fileName = `Original NightlyRoomCount-${tirip["tripCode"]}_${formattedStartDate} - ${formattedEndDate}`;
    // let sheetName = `${tirip["tripCode"]}-${formattedStartDate}-${formattedEndDate}`;
    let sheetName = `${tirip["tripCode"]}`;

    // if (sheetName.length > 31) {
    //   sheetName = sheetName.substring(0, 31);
    // }
    const exportData = this.getReportNightlyArrays.map((item) => {
      delete item.editFieldName;
      const transformedItem = {
        RoomType: item.RoomType,
      };

      Object.entries(item).forEach(([key, value]) => {
        const newKey =
          key !== "RoomType" && /^\d{4}-\d{2}-\d{2}$/.test(key)
            ? this.formatDate(key, "forwardSlash")
            : key;
        transformedItem[newKey] = value;
      });

      return transformedItem;
    });
    this.exportAsExcelFile(exportData, tirip["tripCode"], fileName, this.formatDate(startDate, "forwardSlash"), this.formatDate(endDate, "forwardSlash"), sheetName);
  }

  exportAsExcelFile(
    jsonData: any[],
    tripName: string,
    excelFileName: string,
    fromDate: string = "NA",
    toDate: string = "NA",
    sheetName: string = "NA",
    exportTag: string = ""
  ) {
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Trip Name", tripName],
      ["First Check-In Date", fromDate],
      ["Last Check-Out Date", toDate]
    ]);

    // Define merges for the first 6 rows
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 0, c: 1 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 1, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 2, c: 1 }, e: { r: 2, c: 1 } }
    ];
    worksheet["!merges"] = merge;

    // Add JSON data starting from the 8th row
    XLSX.utils.sheet_add_json(worksheet, jsonData, { origin: "A5" });

    Object.keys(worksheet).forEach((key) => {
      if (key.startsWith("A5")) { // Assuming the headers start from "A5"
        const cell = worksheet[key];
        if (cell && cell.v === "RoomType") {
          delete worksheet[key];
        }
      }
    });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
  }

  formatDate(dateStr: string, formatType: "underscore" | "dash" | "forwardSlash"): string {
    const date = new Date(dateStr);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear().toString().slice(-2);

    switch (formatType) {
      case "underscore":
        return `${month}_${day}_${year}`;
      case "dash":
        return `${month}-${day}-${year}`;
      case "forwardSlash":
        return `${month}/${day}/${date.getFullYear()}`;
      default:
        throw new Error("Invalid format type");
    }
  }
  onEdit(roomAvailability: any, keyOfRoomAvailability: any) {
    // console.log("edit ", roomAvailability ,keyOfRoomAvailability )
    if (roomAvailability.RoomType !== "Available Rooms") {
      this.updatedRoomSlot = roomAvailability[keyOfRoomAvailability];
      roomAvailability.editFieldName = keyOfRoomAvailability;
    }
  }
  mapDataForUpdateNightlyRoomAvailability(item: any, key: string) {
    // Find the total rooms needed for the specific date
    const totalRoomsNeeded = this.roomAvailabilities.find(
      (ra) => ra.RoomType === "Room Needed"
    )[key].slot;
    const selectedRoomType = item["RoomType"];
    const requestedValue = item[key].slot;

    // Calculate the total rooms already allocated for the specific date
    const totalAllocatedRooms = this.roomAvailabilities.reduce(
      (total, roomAvailability) => {
        if (
          roomAvailability.RoomType !== "Room Needed" &&
          roomAvailability.RoomType !== "Available Rooms"
        ) {
          return total + roomAvailability[key].slot;
        } else {
          return total;
        }
      },
      0
    );
    // Find the row for available rooms and update the specific date column
    let availableRoomsRow = this.roomAvailabilities.find(
      (ra) => ra.RoomType === "Available Rooms"
    );

    // Calculate available rooms by subtracting total allocated rooms from total rooms needed
    let calculatedAvailableRooms = totalAllocatedRooms - totalRoomsNeeded;
    availableRoomsRow[key].slot = calculatedAvailableRooms;

    const roomAvailability = this.originalRoomAvailability.find(
      (item) =>
        item["RoomType"] === selectedRoomType && item["RoomAvailability"][key]
    );
    const oldValue = roomAvailability["RoomAvailability"][key]["currentValue"];
    const currentDate = new Date().toISOString();

    const updatedRoomAvailability = this.originalRoomAvailability.map(
      (item) => {
        if (
          item["RoomType"] === selectedRoomType &&
          item["RoomAvailability"][key]
        ) {
          // Destructure existing values if needed
          const { originalValue, currentValue, olderValue, isUpdate, updatedBy, updatedLogs, updatedAt, createdAt } =
            item["RoomAvailability"][key];

          const updateLog = {
            checkInDate: this.formatDate(this.searchForm.value.fromDate, "forwardSlash"),
            originalValue,
            currentValue: requestedValue,
            olderValue: oldValue,
            updatedBy: `${this.loggedUser["fullName"]} (${this.loggedUser["rolesString"]})`,
            UpdatedOn: currentDate
          };

          // Append the new log entry to the existing logs
          // const updatedLogsArray = [...updatedLogs, updateLog];
          item["RoomAvailability"][key] = {
            originalValue,
            currentValue: requestedValue,
            olderValue: oldValue,
            isUpdate: true,
            updatedBy: `${this.loggedUser["fullName"]} (${this.loggedUser["rolesString"]})`,
            updatedLogs,
            updatedAt: currentDate,
            createdAt,
          } as never;
        }

        if (
          item["RoomType"] === "Available Rooms" &&
          item["RoomAvailability"][key]
        ) {
          const { originalValue, currentValue, olderValue, isUpdate, updatedBy, updatedLogs, updatedAt, createdAt } =
            item["RoomAvailability"][key];
          const updateLog = {
            checkInDate: this.formatDate(this.searchForm.value.fromDate, "forwardSlash"),
            originalValue,
            currentValue: requestedValue,
            olderValue: oldValue,
            updatedBy: `${this.loggedUser["fullName"]} (${this.loggedUser["rolesString"]})`,
            UpdatedOn: currentDate
          };

          item["RoomAvailability"][key] = {
            originalValue,
            currentValue: availableRoomsRow[key].slot,
            olderValue: item["RoomAvailability"][key]["currentValue"],
            isUpdate,
            updatedBy: `${this.loggedUser["fullName"]} (${this.loggedUser["rolesString"]})`,
            updatedLogs,
            updatedAt: currentDate,
            createdAt,
          } as never;
        }
        return item;
      }
    );
  }

  saveRoomSlot(e: any, key: string) {
    e.editFieldName = key;
    if (e) {
      e.editFieldName = "";
      if (e[key]) {
        e[key].isEditable = true;
      }
    }
    this.mapDataForUpdateNightlyRoomAvailability(e, key);
    this.updateNightlyRoomAvailability();
  }

  updateNightlyRoomAvailability() {
    let payload = {
      tripId: this.searchForm.value.trip,
      startDate: this.searchForm.value.fromDate
        .toLocaleDateString("en-GB", this.dateFormatOptions)
        .replace(",", "")
        .replace(/ /g, "-"),
      endDate: this.searchForm.value.toDate
        .toLocaleDateString("en-GB", this.dateFormatOptions)
        .replace(",", "")
        .replace(/ /g, "-"),
      roomSlot: JSON.stringify(this.originalRoomAvailability),
    };
    this._reportLogService
      .updateNightlyRoomAvailability(payload)
      .subscribe((result) => {
        this.nightlyRoomAvailability();
      });
  }

  close(e: any, item: any) {
    if (e) {
      e[e.editFieldName] = this.updatedRoomSlot;
      this.updatedRoomSlot = "";
      e.editFieldName = null;
    } else {
    }
  }

  getNightlyCount() {
    let payload = {
      year: this.searchForm.value.year,
      tripId: this.searchForm.value.trip,
      minCheckinDate: this.searchForm.value.fromDate
        .toLocaleDateString("en-GB", this.dateFormatOptions)
        .replace(",", "")
        .replace(/ /g, "-"),
      maxCheckout: this.searchForm.value.toDate
        .toLocaleDateString("en-GB", this.dateFormatOptions)
        .replace(",", "")
        .replace(/ /g, "-"),
    };
    this._reportLogService.getNightlyCount(payload).subscribe((result) => {
      this.model = result.data;
      this.dataSource = result.data;
      this.getReportNightlyArrays = result.data;
      this.getKeyss = Object.keys(this.model[0]);
      let removeRoomType = this.getKeyss.shift();
    });
  }
}
export interface RoomAvailability {
  originalValue: number,
  slot: number;
  isEditable: boolean;
  orginalTooltipText: string;
  currentTooltipText: string;
  updatedBy: string;
}
export interface TransformedItem {
  RoomType: string;
  [date: string]: RoomAvailability | string;
}