import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
} from '@angular/core';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogConfig,
  MatDatepickerInput,
} from '@angular/material';
import { TravelerService } from '../../services/traveler.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';
import { CustomValidators } from '../../Directives/custom-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { GroupOptiosComponent } from '../../Components/group-optios/group-optios.component';
import { TravelerUpdateConfirmationComponent } from '../popup/traveler-update-confirmation/traveler-update-confirmation.component';

@Component({
  selector: 'app-traveler',
  templateUrl: './traveler.component.html',
  styleUrls: ['./traveler.component.css'],
})
export class TravelerComponent implements OnInit {
  enrollmentId: number = 0;
  selected = new FormControl(0);
  tripId: number;
  tripOptionId: number = JSON.parse(localStorage.getItem('user')).tripId;
  isFromItinerary: boolean = false;
  customerId: number;
  AddGroupTitle = 'Add Group Options';
  response: any = {};
  public travelerForm: FormGroup;
  model: any = {};
  sub: any;
  submitted = false;
  showDeleteButton = false;
  role = +localStorage.getItem('role');
  title = '';
  submitButtonText = 'Save';
  travelersList = [];
  travelersHistoryList = [];
  airportList = [];
  airlineList = [];
  data: any = {};
  endDOBDate = new Date();
  dateMask = MaskedDate;
  DefaultNameType = 0;
  defaultNameGen;
  isYesNos = [];
  bedTypes = [];
  drinkChoices = [];
  mailingAddresses = [];
  genders = [];
  groupList = [];
  isYesNosBool = [];
  bedTypeList = [];
  public mask = [
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100)); // new Date(1900, 0, 1);
  maxDateDOB = new Date();
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));

  isGroupAdded: boolean = false;
  displayAuthorizedToSpendPoints = false;

  lastToLastYear = new Date().getFullYear() - 2;
  displayPostTripButton = false;
  displayLinkButton = false;
  LastYear = new Date().getFullYear() - 1;
  displaySaveButton = true;
  years = new Date().getFullYear();
  isVendorSelected: boolean;
  isEmployeeSelected: boolean;
  constructor(
    private _travelerService: TravelerService,
    private _utilityService: UtilityService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<TravelerComponent>,
    @Inject(MAT_DIALOG_DATA) public trips
  ) {
    if (localStorage.getItem('travelerFromItenry')) {
      this.tripOptionId = JSON.parse(
        localStorage.getItem('travelerFromItenry')
      ).tripId;
      this.isFromItinerary = true;
    }
    let index = this.trips
      .map(function (e) {
        return e._TripOptionsId;
      })
      .indexOf(this.tripOptionId);
    if (index >= 0) this.selected.setValue(index);
    else {
      this.tripOptionId = this.trips[0]._TripOptionsId;
    }
  }

  public displayedColumns = ['traveler', 'dateofBirth', 'cellPhone', 'action'];
  public displayedColumnsT = ['traveler', 'dateofBirth', 'groups', 'action'];
  public dataSource = new MatTableDataSource<TravellerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  isFormFieldValueChanged = false;
  public dataSourceT = new MatTableDataSource<TravellerEntity>();

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.enrollmentId = +params['id'];
    });

    this.pageLoad();

    if (!this.trips.displayLinkButton && this.LastYear == this.trips.years) {
      this.displaySaveButton = false;
    }
  }

  onCloseClick(): void {
    localStorage.removeItem('travelerFromItenry');
    this.dialogRef.close({});
    if (+localStorage.getItem('isPoints'))
      this.router.navigate(['/trip-enrollment-detail/' + this.enrollmentId]);
    else this.router.navigate(['/trip-enrollment']);
  }

  get f() {
    return this.travelerForm.controls;
  }

  pageLoad() {
    this.isYesNos = this._utilityService.getIsYesNo();
    this.isYesNosBool = this._utilityService.getIsYesNoBool();
    this.drinkChoices = this._utilityService.getDrinkChoice();
    this.mailingAddresses = this._utilityService.getMailingAddress();
    this.genders = this._utilityService.getGender();

    let tripdetail = this.trips[this.selected.value];
    if (tripdetail) {
      this.enrollmentId = tripdetail._EnrollmentId;
      this.tripId = tripdetail._Id;
      this.customerId = tripdetail._CustomerId;
      this.getTravelerDetailList(this.tripId);
      this.groupList = this._utilityService.fillGroup(tripdetail._NoOfTrips);
      this.title = 'Add Traveler';
      this.showDeleteButton = false;

      this.data.outgoingFlightDate = tripdetail._OutgoingFlightDate;
      this.data.returnFlightDate = tripdetail._ReturnFlightDate;
      this.data.preferredAirportCode = tripdetail._AirportId;
      this.data.preferredAirline = tripdetail._AirLine;
      this.DefaultNameType = tripdetail._DefaultNameType;
      this.generateForm(false);
    }

    if (localStorage.getItem('travelerFromItenry')) {
      let tId = JSON.parse(
        localStorage.getItem('travelerFromItenry')
      ).travelerId;
      this.response = {};
      this._travelerService
        .getTravelerDetail(tId, false)
        .subscribe((result) => {
          this.data = result;
          // debugger;
          this.generateForm(false);
          this.travelerDesignationIdChange(this.data.traverlerDesignationId);
        });
    }
  }
  generateForm(isSave: boolean) {
    if (!isSave) this.response = {};
    if (this.data.id > 0) {
      this.isGroupAdded = true;
      this.AddGroupTitle = 'View Group Options';
    } else {
      this.data.isDepartureFlight = true;
      this.data.isReturnFlight = true;
    }
    if (
      this.data.outgoingLayoverHotelReq == '' ||
      this.data.outgoingLayoverHotelReq == null
    )
      this.data.outgoingLayoverHotelReq = false;

    if (
      this.data.returnLayoverHotelReq == '' ||
      this.data.returnLayoverHotelReq == null
    )
      this.data.returnLayoverHotelReq = false;

    if (this.data.drinkChoice == undefined) this.data.drinkChoice = true;

    if (this.data.companyOwner == undefined) {
      this.data.companyOwner = 0;
    }

    if (this.data.dateofBirth)
      this.data.dateofBirth = new Date(this.data.dateofBirth);
    else this.data.dateofBirth = '';

    if (this.data.passportExpiration)
      this.data.passportExpiration = new Date(this.data.passportExpiration);
    else this.data.passportExpiration = '';

    if (this.data.outgoingFlightDate)
      this.data.outgoingFlightDate = new Date(this.data.outgoingFlightDate);
    else this.data.outgoingFlightDate = '';
    if (this.data.returnFlightDate)
      this.data.returnFlightDate = new Date(this.data.returnFlightDate);
    else this.data.returnFlightDate = '';
    if (
      this.data.passportIssuingCountry == '' ||
      this.data.passportIssuingCountry == null
    )
      this.data.passportIssuingCountry = 'USA';
    if (this.data.preferredAirportCode)
      this.data.preferredAirportCode =
        this.data.preferredAirportCode.toString();
    else this.data.preferredAirportCode = '';

    if (this.data.preferredAirline)
      this.data.preferredAirline = this.data.preferredAirline;
    else this.data.preferredAirline = '';

    if (this.data.traverlerDesignationId) {
      this.data.traverlerDesignationId = this.data.traverlerDesignationId;
      this.displayAuthorizedToSpendPoints = true;
    } else {
      this.data.traverlerDesignationId = '';
      this.displayAuthorizedToSpendPoints = false;
    }

    if (this.data.isAuthorizedtoSpendPoints) {
      this.data.isAuthorizedtoSpendPoints = this.data.isAuthorizedtoSpendPoints;
    } else {
      this.data.isAuthorizedtoSpendPoints = false;
    }

    if (this.data.wheelChairRequest) {
      this.data.wheelChairRequest = this.data.wheelChairRequest;
    } else {
      this.data.wheelChairRequest = '';
    }
    //console.log(this.data.isDepartureFlight);
    //console.log(this.data.isReturnFlight);

    this.travelerForm = this.fb.group({
      Id: [this.data.id],
      TravelerHistoryId: [this.data.travelerHistoryId],
      LegalFirstName: [
        this.data.legalFirstName,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      LegalMiddleName: [this.data.legalMiddleName],
      LegalLastName: [
        this.data.legalLastName,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      LegalSuffix: [this.data.legalSuffix],
      NickName: [this.data.nickName],
      CompanyOwner: [
        this.data.companyOwner == 1 ? true : false,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      DateofBirth: [this.data.dateofBirth],
      Gender: [
        this.data.gender,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      CellPhone: [this.data.cellPhone],
      EmailAddress: [this.data.emailAddress, [Validators.email]], //Validators.required, CustomValidators.checkEmpty,
      DefaultName: [this.data.defaultName],
      PassportName: [this.data.passportName],
      TrustedTraveler: [this.data.trustedTraveler],
      Passport: [this.data.passport],
      PassportExpiration: [this.data.passportExpiration],
      PassportIssuingCountry: [this.data.passportIssuingCountry],
      PreferredAirportCode: [this.data.preferredAirportCode],
      PreferredAirline: [this.data.preferredAirline],
      PrefAirlineFreqFlier: [this.data.prefAirlineFreqFlier],
      OutgoingFlightDate: [this.data.outgoingFlightDate],
      ReturnFlightDate: [this.data.returnFlightDate],
      DrinkChoice: [this.data.drinkChoice, [Validators.required]],
      Groups: [
        this.data.groups ? this.data.groups : '',
        [Validators.required, CustomValidators.checkEmpty],
      ], //
      Filter: [''],
      IsDepartureFlight: [this.data.isDepartureFlight],
      IsReturnFlight: [this.data.isReturnFlight],
      TraverlerDesignationId: [this.data.traverlerDesignationId],

      IsAuthorizedtoSpendPoints: [this.data.isAuthorizedtoSpendPoints],
      WheelChairRequest: [this.data.wheelChairRequest],
    });

    // Check Value Change in Form Field
    this.travelerForm.get('LegalFirstName').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('LegalMiddleName').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('LegalLastName').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('LegalSuffix').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('NickName').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('DateofBirth').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('Gender').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('CellPhone').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('EmailAddress').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('DefaultName').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('PassportName').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });

    this.travelerForm.get('Passport').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm
      .get('PassportExpiration')
      .valueChanges.subscribe((value) => {
        this.isFormFieldValueChanged = true;
      });
    this.travelerForm
      .get('PassportIssuingCountry')
      .valueChanges.subscribe((value) => {
        this.isFormFieldValueChanged = true;
      });

    this.travelerForm
      .get('PrefAirlineFreqFlier')
      .valueChanges.subscribe((value) => {
        this.isFormFieldValueChanged = true;
      });
    this.travelerForm.get('DrinkChoice').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });

    this.travelerForm
      .get('TraverlerDesignationId')
      .valueChanges.subscribe((value) => {
        this.isFormFieldValueChanged = true;
      });
    this.travelerForm
      .get('IsAuthorizedtoSpendPoints')
      .valueChanges.subscribe((value) => {
        this.isFormFieldValueChanged = true;
      });
    this.travelerForm
      .get('WheelChairRequest')
      .valueChanges.subscribe((value) => {
        this.isFormFieldValueChanged = true;
      });
    this.travelerForm.get('CompanyOwner').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });
    this.travelerForm.get('TrustedTraveler').valueChanges.subscribe((value) => {
      this.isFormFieldValueChanged = true;
    });

    //added on 23/05/2024
    this.travelerForm.get('TraverlerDesignationId').valueChanges.subscribe(value => {
      debugger;
      // if (value === '2') { //Vendor = 2
      //   this.travelerForm.get('IsDepartureFlight').setValue(false);
      //   this.travelerForm.get('IsDepartureFlight').disable();
      //   this.travelerForm.get('IsReturnFlight').setValue(false);
      //   this.travelerForm.get('IsReturnFlight').disable();
      //   this.isVendorSelected = true;
      //   //this.isEmployeeSelected = false;
      // } else {
      //   this.travelerForm.get('IsDepartureFlight').setValue(false);
      //   this.travelerForm.get('IsDepartureFlight').enable();
      //   this.travelerForm.get('IsReturnFlight').setValue(false);
      //   this.travelerForm.get('IsReturnFlight').enable();
      //   this.isVendorSelected = false;
      // }
      this.travelerDesignationIdChange(+value);
    });
    

  }

  travelerDesignationIdChange(value:number) {

    if (value === 1) { //Vendor = 2
      this.travelerForm.get('IsDepartureFlight').setValue(true);
      this.travelerForm.get('IsDepartureFlight').enable();
      this.travelerForm.get('IsReturnFlight').setValue(true);
      this.travelerForm.get('IsReturnFlight').enable();
      //this.isVendorSelected = true;
      this.isEmployeeSelected = true;
    } else {
      this.travelerForm.get('IsDepartureFlight').setValue(false);
      this.travelerForm.get('IsDepartureFlight').disable();
      this.travelerForm.get('IsReturnFlight').setValue(false);
      this.travelerForm.get('IsReturnFlight').disable();
      //this.isVendorSelected = false;
      this.isEmployeeSelected = false;
    }

  }


  saveTraveler() {
    let dataSave = this.travelerForm.value;
    this.response = {};
    this.submitted = true;
    if (!this.isGroupAdded && this.f.Groups.value) {
      this.response = {
        isShow: true,
        message:
          'Group options not added for selected group, so please first add group options.',
      };
      this.AddGroupTitle = 'Add Group Options';
      return;
    }

    if (this.travelerForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    var id = dataSave.Id;
    if (!dataSave.Id) id = 0;

    var outgoingFlightDate = '';
    var returnFlightDate = '';
    var dob = '';
    var passportExpiration = '';

    if (dataSave.DateofBirth)
      dob = new Date(dataSave.DateofBirth).toDateString();
    if (dataSave.PassportExpiration)
      passportExpiration = new Date(dataSave.PassportExpiration).toDateString();

    if (dataSave.OutgoingFlightDate)
      outgoingFlightDate = new Date(dataSave.OutgoingFlightDate).toDateString();
    if (dataSave.ReturnFlightDate)
      returnFlightDate = new Date(dataSave.ReturnFlightDate).toDateString();

    var traverlerDesignationId = 0;
    if (dataSave.TraverlerDesignationId) {
      traverlerDesignationId = parseInt(dataSave.TraverlerDesignationId);
    }
    this.model = {
      Id: id,
      TripId: this.tripId,
      CustomerId: this.customerId,
      LegalFirstName: dataSave.LegalFirstName,
      LegalMiddleName: dataSave.LegalMiddleName,
      LegalLastName: dataSave.LegalLastName,
      LegalSuffix: dataSave.LegalSuffix,
      NickName: dataSave.NickName,
      CompanyOwner: Boolean(dataSave.CompanyOwner) ? 1 : 0,
      DateofBirth: dob,
      Gender: dataSave.Gender,
      CellPhone: dataSave.CellPhone,
      EmailAddress: dataSave.EmailAddress,
      DefaultName: dataSave.DefaultName,
      PassportName: dataSave.PassportName,
      TrustedTraveler: dataSave.TrustedTraveler,
      Passport: dataSave.Passport,
      PassportExpiration: passportExpiration,
      PassportIssuingCountry: dataSave.PassportIssuingCountry,
      PreferredAirportCode: +dataSave.PreferredAirportCode,
      PreferredAirline: +dataSave.PreferredAirline,
      PrefAirlineFreqFlier: dataSave.PrefAirlineFreqFlier,
      DrinkChoice: Boolean(dataSave.DrinkChoice),
      Groups: dataSave.Groups,
      OutgoingFlightDate: outgoingFlightDate,
      ReturnFlightDate: returnFlightDate,
      IsDepartureFlight: Boolean(dataSave.IsDepartureFlight),
      IsReturnFlight: Boolean(dataSave.IsReturnFlight),
      LogUserId: this._utilityService.getCurrentUserId(),
      TraverlerDesignationId: traverlerDesignationId,
      IsAuthorizedtoSpendPoints: Boolean(dataSave.IsAuthorizedtoSpendPoints),
      WheelChairRequest: Boolean(dataSave.WheelChairRequest),
      UpdateTravelerProfileForOtherTrip: false,
      TravelerHistoryId: +dataSave.TravelerHistoryId,
    };

    // console.log(this.isFormFieldValueChanged);

    // Check if traveler profile is changed and traveler history id is greater than 0
    if (this.isFormFieldValueChanged && this.model.TravelerHistoryId > 0) {
      let profileCheck = {
        TravelerHistoryId: this.model.TravelerHistoryId,
        TripId: this.model.TripId,
      };
      // Check if traveler profile is used in another trip
      this._travelerService
        .CheckTravelerRegisterOnAnotherTrip(profileCheck)
        .subscribe((result) => {
          if (result) {
            if (result.messageCode == 1 && result.data > 0) {
              this.displayConfirmationMessageToProfileUpdate(
                this.model,
                result.message
              );
            } else {
              this.callSaveTravelerService(this.model);
            }
          }
        });
    } else {
      this.callSaveTravelerService(this.model);
    }
  }

  displayConfirmationMessageToProfileUpdate(saveData: any, pageTitle: string) {
    // debugger;
    let data = {
      pageTitle: pageTitle,
    };

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '350px';
    dialogConfig.data = data;
    const modalDialog = this.matDialog.open(
      TravelerUpdateConfirmationComponent,
      dialogConfig
    );

    modalDialog.afterClosed().subscribe({
      next: (data) => {
        saveData.UpdateTravelerProfileForOtherTrip = data;
        this.callSaveTravelerService(saveData);
      },
    });
  }

  callSaveTravelerService(saveData: any) {
    this._travelerService.saveTraveler(saveData).subscribe((result) => {
      if (result) {
        if (result.messageCode == 1) {
          this.isFormFieldValueChanged = false;
          if (this.isFromItinerary) {
            // this.router.navigate(['/trip-enrollment-detail',this.model.Id]);
            localStorage.removeItem('travelerFromItenry');
            this.dialogRef.close(result);
          } else {
            this.response = result;
            this._travelerService
              .getRegTravelerList(this.tripId)
              .subscribe((result) => {
                this.travelersList = result;
                this.dataSourceT.data = this.travelersList as TravellerEntity[];

                this._travelerService
                  .getTravelerHistoryList(this.tripId)
                  .subscribe((history) => {
                    this.travelersHistoryList = history;
                    this.dataSource.data = this
                      .travelersHistoryList as TravellerEntity[];
                  });
              });
            this.data = {};
            let tripdetail = this.trips[this.selected.value];
            if (tripdetail) {
              this.data.outgoingFlightDate = tripdetail._OutgoingFlightDate;
              this.data.returnFlightDate = tripdetail._ReturnFlightDate;
              this.data.preferredAirportCode = tripdetail._AirportId;
              this.data.preferredAirline = tripdetail._AirLine;
            }
            
            this.generateForm(true);
            this.submitted = false;
          }
        } else {
          this.response = result;
        }
      }
    });
  }

  deleteTraveler(id: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this._travelerService
        .deleteTraveler(id, this._utilityService.getCurrentUserId())
        .subscribe((result) => {
          this.response = result;
          if (result.messageCode == 1) {
            this._travelerService
              .getRegTravelerList(this.tripId)
              .subscribe((result) => {
                this.travelersList = result;
                this.dataSourceT.data = this.travelersList as TravellerEntity[];

                this._travelerService
                  .getTravelerHistoryList(this.tripId)
                  .subscribe((history) => {
                    this.travelersHistoryList = history;
                    this.dataSource.data = this
                      .travelersHistoryList as TravellerEntity[];
                  });
              });
          } else {
            this.response = result;
          }
        });
    }
  }

  deleteTravelerHistory(id: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this._travelerService.deleteTravelerHistory(id).subscribe((result) => {
        this.response = result;
        if (result.messageCode == 1) {
          this._travelerService
            .getRegTravelerList(this.tripId)
            .subscribe((result) => {
              this.travelersList = result;
              this.dataSourceT.data = this.travelersList as TravellerEntity[];

              this._travelerService
                .getTravelerHistoryList(this.tripId)
                .subscribe((history) => {
                  this.travelersHistoryList = history;
                  this.dataSource.data = this
                    .travelersHistoryList as TravellerEntity[];
                });
            });
        } else {
          this.response = result;
        }
      });
    }
  }

  getTravelerDetailList(tripId) {
    this._travelerService.getTravelerDetailList(tripId).subscribe((result) => {
      let travelerDetailList = result;
      this.travelersHistoryList = travelerDetailList.travelerHistoryList;
      this.airportList = travelerDetailList.airportList;
      this.airlineList = travelerDetailList.airlineList;
      this.travelersList = travelerDetailList.travelerList;
      this.dataSource.data = this.travelersHistoryList as TravellerEntity[];

      this.dataSourceT.data = this.travelersList as TravellerEntity[];
    });
  }

  config = {
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 5, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'airlineCode', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr', // the direction of the search input can be rtl or ltr(default)
  };
  changeTab(index: number) {
    this.f.Filter.setValue('');
    this.response = {};
    this.submitted = false;
    let tripdetail = this.trips[index];
    this.tripId = tripdetail._Id;
    this.tripOptionId = tripdetail._TripOptionsId;
    this.DefaultNameType = tripdetail._DefaultNameType;
    this.groupList = this._utilityService.fillGroup(tripdetail._NoOfTrips);

    this._travelerService
      .getRegTravelerList(this.tripId)
      .subscribe((result) => {
        this.travelersList = result;
        this.dataSourceT.data = this.travelersList as TravellerEntity[];

        this._travelerService
          .getTravelerHistoryList(this.tripId)
          .subscribe((history) => {
            this.travelersHistoryList = history;

            this.dataSource.data = this
              .travelersHistoryList as TravellerEntity[];
            this.applyFilter('');
          });

        this.data = {};
        this.data.outgoingFlightDate = tripdetail._OutgoingFlightDate;
        this.data.returnFlightDate = tripdetail._ReturnFlightDate;
        this.data.preferredAirportCode = tripdetail._AirportId;
        this.data.preferredAirline = tripdetail._AirLine;
        this.generateForm(false);
      });
    this.selected.setValue(index);
  }

  selectTraveler(id: number, isHistory: boolean) {
    this.response = {};
    this._travelerService
      .getTravelerDetail(id, isHistory)
      .subscribe((result) => {
        this.data = result;

        if (isHistory) {
          let tripdetail = this.trips[this.selected.value];
          this.data.outgoingFlightDate = tripdetail._OutgoingFlightDate;
          this.data.returnFlightDate = tripdetail._ReturnFlightDate;
          this.data.preferredAirportCode = tripdetail._AirportId;
          this.data.preferredAirline = tripdetail._AirLine;
        }

        this.generateForm(false);
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  changeGroup() {
    if (this.f.Groups.value) {
      this._travelerService
        .checkGroupAdded(
          this.customerId,
          this.tripOptionId,
          this.f.Groups.value
        )
        .subscribe((result) => {
          if (result) this.AddGroupTitle = 'View Group Options';
          else this.AddGroupTitle = 'Add Group Options';
          this.isGroupAdded = result;
        });
    }
  }
  changeTravelerDesignation() {
    var selectedValue = this.f.TraverlerDesignationId.value;
    this.displayAuthorizedToSpendPoints = false;
    if (selectedValue) {
      this.displayAuthorizedToSpendPoints = true;
    } else {
      this.f.IsAuthorizedtoSpendPoints.setValue(false);
    }
  }

  addGroup(groups: number, isGroupAdded: boolean = false) {
    this.response = { isShow: false };
    let tripData = this.trips[this.selected.value];
    this._travelerService
      .getGroupDetail(tripData._CustomerId, this.tripOptionId, groups)
      .subscribe((result) => {
        this.data = result;
        let groupDetail = { ...tripData, ...result };
        groupDetail.tripOptionId = this.tripOptionId;
        groupDetail.displayLinkButton = this.trips.displayLinkButton; // GET AR Status
        groupDetail.years = this.trips.years;
        groupDetail.isGroupAdded = isGroupAdded
          ? isGroupAdded
          : this.isGroupAdded;

        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '800px';
        dialogConfig.data = groupDetail;
        const modalDialog = this.matDialog.open(
          GroupOptiosComponent,
          dialogConfig
        );

        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == 1) {
              this.AddGroupTitle = 'View Group Options';
              this.isGroupAdded = true;
            }
          }
        });
      });
  }
  generateDefaultName() {
    if (this.f.LegalFirstName.value) {
      this.defaultNameGen = this.f.LegalFirstName.value;
      if (this.f.LegalMiddleName.value)
        this.defaultNameGen += ' ' + this.f.LegalMiddleName.value;
      if (this.f.LegalLastName.value)
        this.defaultNameGen += ' ' + this.f.LegalLastName.value;
      if (this.f.LegalSuffix.value)
        this.defaultNameGen += ' (' + this.f.LegalSuffix.value + ')';
      this.f.DefaultName.setValue(this.defaultNameGen);
      this.f.PassportName.setValue(this.defaultNameGen);
    } else {
      this.f.DefaultName.setValue('');
      this.f.PassportName.setValue('');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

export interface TravellerEntity {
  id: number;
  legalFirstName: string;
  legalMiddleName: string;
  legalLastName: string;
  legalSuffix: string;
  dateofBirth: Date;
  cellPhone: string;
  groups: number;
}

export function MaskedDate(event: any) {
  var v = event.target.value;
  if (v.match(/^\d{2}$/) !== null) {
    event.target.value = v + '/';
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    event.target.value = v + '/';
  }
}
