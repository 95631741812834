import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  getCurrentDateTime(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    let currentDateTime =
      year + '-' + this.appendZero(month) + '-' + this.appendZero(day) + ' ';
    const now = new Date();
    const options: any = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    const formattedTime12Hour = now.toLocaleString('en-US', options);
    //console.log(formattedTime12Hour);

    // console.log(currentDateTime);
    return currentDateTime + formattedTime12Hour.replace(':', '_');
  }
  appendZero(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  getRoles(): KeyValue[] {
    return this.roles;
  }
  roles: KeyValue[] = [
    { text: 'Admin', value: 2 },
    { text: 'Regular', value: 3 },
    { text: 'Report Only', value: 4 },
  ];

  isYesNo: KeyValue[] = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 },
  ];

  isYesNoBool: KeyValueBool[] = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  fillYear(iStart, iEnd) {
    let year = [];
    for (let i = iStart; i <= iEnd; i++) year.push(i);
    return year;
  }

  fillGroup(noOfTrip) {
    let group = [];
    for (let i = 1; i <= noOfTrip; i++) group.push(i);
    return group;
  }

  getIsYesNo(): KeyValue[] {
    return this.isYesNo;
  }

  getIsYesNoBool(): KeyValueBool[] {
    return this.isYesNoBool;
  }
  getCurrentUserId() {
    let userId = 0;
    let currentUser = JSON.parse(localStorage.getItem('user'));
    // console.log(currentUser);
    if (currentUser) {
      userId = currentUser.id;
    }
    return userId;
  }

  getCommonMethod(): KeyValueString[] {
    return this.commonMethod;
  }
  commonMethod: KeyValueString[] = [
    { text: 'Email', value: 'Email' },
    { text: 'Snail Mail', value: 'Snail Mail' },
    { text: 'Phone', value: 'Phone' },
  ];

  timeZone: KeyValueString[] = [
    { text: 'HST - Hawaii Standard Time', value: 'HST' },
    { text: 'HDT - Hawaii-Aleutian Daylight Time', value: 'HDT' },
    { text: 'AKDT - Alaska Daylight Time', value: 'AKDT' },
    { text: 'PDT - Pacific Daylight Time', value: 'PDT' },
    { text: 'MST - Mountain Standard Time', value: 'MST' },
    { text: 'MDT - Mountain Daylight Time', value: 'MDT' },
    { text: 'CDT - Central Daylight Time', value: 'CDT' },
    { text: 'EDT - Eastern Daylight Time', value: 'EDT' },
  ];
  getTimeZone(): KeyValueString[] {
    return this.timeZone;
  }
  nameType: KeyValue[] = [
    { text: 'Driver License', value: 1 },
    { text: 'Passport', value: 2 },
  ];

  getNameType(): KeyValue[] {
    return this.nameType;
  }

  getBedType(): KeyValue[] {
    return this.bedType;
  }

  bedType: KeyValue[] = [
    { text: 'King', value: 1 },
    { text: 'Double', value: 2 },
    { text: 'Handicap Accessible', value: 3 },
  ];

  getDrinkChoice(): KeyValueBool[] {
    return this.drinkChoice;
  }

  drinkChoice: KeyValueBool[] = [
    { text: 'Alcohol', value: true },
    { text: 'Non Alcohol', value: false },
  ];
  getMailingAddress(): KeyValue[] {
    return this.mailingAddress;
  }
  getMonthList(): KeyValue[] {
    return this.monthList;
  }
  mailingAddress: KeyValue[] = [
    { text: 'Use SXE Address', value: 1 },
    { text: 'Use DAP Address', value: 2 },
    { text: 'Specific Address', value: 3 },
  ];

  monthList: KeyValue[] = [
    { text: 'January', value: 1 },
    { text: 'February', value: 2 },
    { text: 'March', value: 3 },
    { text: 'April', value: 4 },
    { text: 'May', value: 5 },
    { text: 'June', value: 6 },
    { text: 'July', value: 7 },
    { text: 'August', value: 8 },
    { text: 'September', value: 9 },
    { text: 'October', value: 10 },
    { text: 'November', value: 11 },
    { text: 'December', value: 12 },
  ];

  getGender(): KeyValue[] {
    return this.gender;
  }
  getYesNo(): KeyValue[] {
    return this.yesNo;
  }
  yesNo: KeyValue[] = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 },
  ];

  gender: KeyValue[] = [
    { text: 'Male', value: 1 },
    { text: 'Female', value: 2 },
  ];

  getFlightType(): KeyValue[] {
    return this.flight;
  }

  flight: KeyValue[] = [
    { text: 'Departure', value: 0 },
    { text: 'Return', value: 1 },
  ];
}

interface KeyValue {
  text: string;
  value: number;
}
interface KeyValueString {
  text: string;
  value: string;
}

interface KeyValueBool {
  text: string;
  value: boolean;
}
