import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TravelerService } from '../../services/traveler.service';
import { CustomValidators } from 'src/app/Directives/custom-validators';
import { UtilityService } from '../../services/utility.service';
@Component({
  selector: 'app-group-optios',
  templateUrl: './group-optios.component.html',
  styleUrls: ['./group-optios.component.css'],
})
export class GroupOptiosComponent implements OnInit {
  public groupForm: FormGroup;
  response: any = {};
  submitted = false;
  title = '';
  model = {};
  mailingAddresses = [];
  pointsOptionList1 = [];
  pointsOptionList2 = [];
  pointsOptionList3 = [];
  pointsOptionList4 = [];
  pointsOptionList5 = [];
  pointsOptionList6 = [];
  role = +localStorage.getItem('role');
  dateMask = MaskedDate;
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100));
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));

  displayLinkButton = false;
  displaySaveButton = true;
  LastYear = new Date().getFullYear() - 1;

  HotelDesignationDetailModel : any[] = [];
  tripOptionsID: number;

  constructor(
    private _utilityService: UtilityService,
    private travelerService: TravelerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GroupOptiosComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (this.data._BedTypeList.length > 0 && this.data._Id <= 0) {
      this.data._BedTypeList = this.data._BedTypeList.filter((b) => b.isActive);
    } else if (this.data._BedTypeList.length > 0 && this.data._Id > 0) {
      this.data._BedTypeList = this.data._BedTypeList.filter(
        (b) => b.isActive || b.id === this.data._PreferredBedType
      );
    }
    if (this.data._PointsOptionList.length > 0 && this.data._Id <= 0) {
      this.data._PointsOptionList = this.data._PointsOptionList.filter(
        (b) => b._IsActive
      );
    }
  }

  ngOnInit() {

    this.mailingAddresses = this._utilityService.getMailingAddress();
    // this.travelerService.getGroupDetail(this.tripData._CustomerId,this.tripData._TripOptionsId,this.tripData._Groups).subscribe((result) => {
    // 	debugger;
    // 	this.data=result;
    // 	  });
    this.pointsOptionList1 =
      this.pointsOptionList2 =
      this.pointsOptionList3 =
      this.pointsOptionList4 =
      this.pointsOptionList5 =
      this.pointsOptionList6 =
        this.data._PointsOptionList;
    this.generateForm();

    if (!this.data.displayLinkButton && this.LastYear == this.data.years) {
      this.displaySaveButton = false;
    }
    this.checkGroupOptionDuplicate();
  }
  get f() {
    return this.groupForm.controls;
  }

  generateForm() {
    if (this.data._BedTypeList.length === 0) {
      this.response = {
        isShow: true,
        message:
          'There is no any room types added for this trip, so please first add the room types for this trip.',
      };
    } else if (this.data._PointsOptionList.length === 0) {
      this.response = {
        isShow: true,
        message:
          'There is no any point options, so please first add the point options for this trip.',
      };
    }
    let option1 = '';
    let option2 = '';
    let option3 = '';
    let option4 = '';
    let option5 = '';
    let option6 = '';
    if (this.data._GroupPointOptionsList.length > 0) {
      option1 = this.data._GroupPointOptionsList[0]._OptionId;
      this.pointsOptionList1 = this.pointsOptionList1.filter(
        (b) => b._IsActive || b._OptionId === option1
      );
    }

    if (this.data._GroupPointOptionsList.length > 1) {
      option2 = this.data._GroupPointOptionsList[1]._OptionId;
      this.pointsOptionList2 = this.pointsOptionList2.filter(
        (b) => b._IsActive || b._OptionId === option2
      );
    }

    if (this.data._GroupPointOptionsList.length > 2) {
      option3 = this.data._GroupPointOptionsList[2]._OptionId;
      this.pointsOptionList3 = this.pointsOptionList3.filter(
        (b) => b._IsActive || b._OptionId === option3
      );
    }

    if (this.data._GroupPointOptionsList.length > 3) {
      option4 = this.data._GroupPointOptionsList[3]._OptionId;
      this.pointsOptionList4 = this.pointsOptionList4.filter(
        (b) => b._IsActive || b._OptionId === option4
      );
    }

    if (this.data._GroupPointOptionsList.length > 4) {
      option5 = this.data._GroupPointOptionsList[4]._OptionId;
      this.pointsOptionList5 = this.pointsOptionList5.filter(
        (b) => b._IsActive || b._OptionId === option5
      );
    }

    if (this.data._GroupPointOptionsList.length > 5) {
      option6 = this.data._GroupPointOptionsList[5]._OptionId;
      this.pointsOptionList6 = this.pointsOptionList6.filter(
        (b) => b._IsActive || b._OptionId === option6
      );
    }

    //this.pointsOptionList1=this.pointsOptionList1.filter(o1=>o1._OptionId!=option2 && o1._OptionId!=option3 && o1._OptionId!=option4 && o1._OptionId!=option5 && o1._OptionId!=option6)
    if (this.data.checkInDate)
      this.data.checkInDate = new Date(this.data.checkInDate);
    else if (this.data._CheckInDate)
      this.data.checkInDate = new Date(this.data._CheckInDate);
    else this.data.checkInDate = '';
    if (this.data.checkOutDate)
      this.data.checkOutDate = new Date(this.data.checkOutDate);
    else if (this.data._CheckOutDate)
      this.data.checkOutDate = new Date(this.data._CheckOutDate);
    else this.data.checkOutDate = '';

    let stdBedType = this.data._BedTypeList.filter(
      (b) => b.roomType == 'Standard King'
    )[0];
    if (!this.data._PreferredBedType && stdBedType)
      this.data._PreferredBedType = stdBedType.id;

    this.groupForm = this.fb.group({
      _Id: [this.data._Id, Validators.required],
      checkInDate: [this.data.checkInDate, [Validators.required]],
      checkOutDate: [this.data.checkOutDate, [Validators.required]],
      _MailingAddress: [this.data._MailingAddress, [Validators.required]],
      _FedExAddress1: [
        this.data._FedExAddress1,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      _FedExAddress2: [this.data._FedExAddress2],
      _FedExAddress3: [this.data._FedExAddress3],
      _FedExCity: [
        this.data._FedExCity,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      _FedExState: [
        this.data._FedExState,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      _FedExZip: [
        this.data._FedExZip,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      _MiscGroupRequest: [this.data._MiscGroupRequest],
      _MiscGroupPoint: [
        this.data._MiscGroupPoint != 0 ? this.data._MiscGroupPoint : '',
      ],
      _Notes: [this.data._Notes, [Validators.maxLength(150)]],
      _RentACar: [this.data._RentACar],
      //_WheelChairRequest: [this.data._WheelChairRequest],
      _WheelChairRequest: [false],
      _CompanyOwner: [this.data._CompanyOwner],
      _FirstClass: [this.data._FirstClass],
      _WeekDesignation: [
        this.data._WeekDesignation != 0 ? this.data._WeekDesignation : '',
      ],

      _PreferredBedType: [
        this.data._PreferredBedType ? this.data._PreferredBedType : '',
        [Validators.required],
      ],

      _Option1: [option1],
      _Option2: [option2],
      _Option3: [option3],
      _Option4: [option4],
      _Option5: [option5],
      _Option6: [option6],

      _HotelDesignationID : [this.data._HotelDesignationID, [Validators.required]],
    });
    this.getHotelDetailLists();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  getMailingAddress(mailingAddress: number) {
    this.response = {};
    if (mailingAddress != 3) {
      this.travelerService
        .getMailingAddress(this.data._CustomerId, mailingAddress)
        .subscribe((address) => {
          if (address) {
            this.f._FedExAddress1.setValue(address.fedExAddress1);
            this.f._FedExAddress2.setValue(address.fedExAddress2);
            this.f._FedExAddress3.setValue(address.fedExAddress3);
            this.f._FedExCity.setValue(address.fedExCity);
            this.f._FedExState.setValue(address.fedExState);
            this.f._FedExZip.setValue(address.fedExZip);
          }
        });
      //this.isReadOnlyAddress=true;
    } else {
      // this.isReadOnlyAddress=false;
      this.f._FedExAddress1.setValue('');
      this.f._FedExAddress2.setValue('');
      this.f._FedExAddress3.setValue('');
      this.f._FedExCity.setValue('');
      this.f._FedExState.setValue('');
      this.f._FedExZip.setValue('');
    }
  }

  saveGroup() {
    this.submitted = true;

    if (this.groupForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    let dataSave = this.groupForm.value;
    // let poBox='box'
    // if (dataSave._FedExAddress1.toLowerCase().includes(poBox) || dataSave._FedExAddress2.toLowerCase().includes(poBox) || dataSave._FedExAddress3.toLowerCase().includes(poBox) || dataSave._FedExZip.toLowerCase().includes(poBox)) {
    // 	this.response = { isShow: true,
    // 	message:'Address1, Address2 and Address3 can not contain PO Box, So please remove it and try again.' };
    // 	return;
    // }
    var id = dataSave._Id;
    if (!dataSave._Id) id = 0;

    let groupPointOptionsList = [];
    if (dataSave._Option1)
      groupPointOptionsList.push({ _OptionId: dataSave._Option1 });
    if (dataSave._Option2)
      groupPointOptionsList.push({ _OptionId: dataSave._Option2 });
    if (dataSave._Option3)
      groupPointOptionsList.push({ _OptionId: dataSave._Option3 });
    if (dataSave._Option4)
      groupPointOptionsList.push({ _OptionId: dataSave._Option4 });
    if (dataSave._Option5)
      groupPointOptionsList.push({ _OptionId: dataSave._Option5 });
    if (dataSave._Option6)
      groupPointOptionsList.push({ _OptionId: dataSave._Option6 });

    var _checkInDate = '';
    var _checkOutDate = '';

    if (dataSave.checkInDate)
      _checkInDate = new Date(dataSave.checkInDate).toDateString();
    if (dataSave.checkOutDate)
      _checkOutDate = new Date(dataSave.checkOutDate).toDateString();

    var weekDesignation = 0;
    if (dataSave._WeekDesignation) {
      weekDesignation = parseInt(dataSave._WeekDesignation);
    }
    var hotelDesignationID =  0;
    if (dataSave._HotelDesignationID) {
      hotelDesignationID = parseInt(dataSave._HotelDesignationID);
    }

    this.model = {
      _Id: id,
      _TripId: this.data._TripId,
      _Groups: this.data._Groups,
      customerId: this.data._CustomerId,
      checkInDate: _checkInDate,
      checkOutDate: _checkOutDate,
      _MailingAddress: dataSave._MailingAddress,
      _FedExAddress1: dataSave._FedExAddress1,
      _FedExAddress2: dataSave._FedExAddress2,
      _FedExAddress3: dataSave._FedExAddress3,
      _FedExCity: dataSave._FedExCity,
      _FedExState: dataSave._FedExState,
      _FedExZip: dataSave._FedExZip,
      _MiscGroupRequest: dataSave._MiscGroupRequest,
      _MiscGroupPoint: +dataSave._MiscGroupPoint,
      _RentACar: dataSave._RentACar,
      _WheelChairRequest: dataSave._WheelChairRequest,
      _PreferredBedType: dataSave._PreferredBedType,
      _GroupPointOptionsList: groupPointOptionsList,
      _Notes: dataSave._Notes,
      _LogUserId: this._utilityService.getCurrentUserId(),
      _CompanyOwner: dataSave._CompanyOwner,
      _FirstClass: dataSave._FirstClass,
      _WeekDesignation: weekDesignation,
      _HotelDesignationID: hotelDesignationID
    };
    //console.log(this.model);
    this.travelerService.saveGroupOption(this.model).subscribe((result) => {
      if (result) {
        if (result.messageCode == 1) {
          this.dialogRef.close(result);
        } else {
          this.response = result;
        }
      }
    });
  }
  getHotelDetailLists(){
 // console.log(this.data.tripOptionId);
    this.travelerService.getHotelDetailList(this.data.tripOptionId).subscribe((result) => {
      if (result) {
        this.HotelDesignationDetailModel = result;
      }
    });
  }
  checkGroupOptionDuplicate() {
    //debugger;
    let groupOptionData = {
      Id: this.data.isGroupAdded ? this.data._Id : 0,
      CustomerId: this.data._CustomerId,
      TripId: this.data._TripId,
      Groups: this.data._Groups,
    };

    this.travelerService
      .checkDuplicateGroupOptions(groupOptionData)
      .subscribe((result) => {
        if (result.messageCode == 2) {
          this.response = result;
        }
      });
  }
  numberOnlywithMinus(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 45) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ChangeOption(optionNo: number) {
    // let	optionsList=this.data._PointsOptionList
    //    if(optionNo==1)
    //    {
    // 	this.pointsOptionList1
    //    }
  }
}

export function MaskedDate(event: any) {
  var v = event.target.value;
  if (v.match(/^\d{2}$/) !== null) {
    event.target.value = v + '/';
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    event.target.value = v + '/';
  }
}
