import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EnrollmentService } from '../../services/enrollment.service';
import { UtilityService } from '../../services/utility.service';
import { FormControl } from '@angular/forms';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { ViewlinkComponent } from '../viewlink/viewlink.component';

@Component({
  selector: 'app-customer-list-points',
  templateUrl: './customer-list-points.component.html',
  styleUrls: ['./customer-list-points.component.css']
})
export class CustomerListPointsComponent implements OnInit {
  yearList: any = [];
	selected = new FormControl(1);
	years = new Date().getFullYear();
	data: any={}
	response: any = {};
	model = {};
  sub: any;
  settings: any;
  from :number=1; to:number=25; totalRecord:number;
  constructor(private enrollmentService: EnrollmentService,
		private _router: Router,
		private matDialog: MatDialog,
		private _utilityService: UtilityService,private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string) { 
      
      this.settings = {
        columns: {
          customerNumber: {
            title: 'Company No.',
            sort: false,
            type: 'custom',
            renderComponent: ViewlinkComponent
          },
          companyName: {
            title: 'Company Name',
            
            sort: false
          },
          dapContact: {
            title: 'DAP Contact',
            sort: false
          },
          dapPhone: {
            title: 'DAP Phone',
            sort: false
          },
          ppewhsCodeString: {
            title: 'WHS',
            sort: false
          },
          groupManager: {
            title: 'Group Mgr.',
            sort: false
          },
          tripEnrolled: {
            title: 'Trip Enrolled',
            sort: false,
            filter: false,
          },
          
        },
        noDataMessage: "No record found.",
        actions: {
          edit: false,
          add: false,
          delete: false,
          custom: [
            {
              name: 'Edit',
              title: `<span  class="rounded-circle px-2 btn-gray py-1 border mr-2 text-secondary"><i aria-hidden="true" class="mb-0 fa fa-pencil"></i> </span>`
            }
          ],
          position: 'right'
        },
        pager: {
          display: true,
          perPage: 25
        },
        attr: {
          class: 'table table color-table dark-table table-striped'
        }
      };

    }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 2, new Date().getFullYear());
    this.enrollmentList();

    this.data.onChanged().subscribe((change) => {			
			this.from=((change.paging.page-1)*change.paging.perPage)+1;
			this.to=this.data.lastRequestCount <=change.paging.page*change.paging.perPage?this.data.lastRequestCount:change.paging.page*change.paging.perPage;
		});
  }
  ngAfterViewInit() {
	}
  dropdownYearChange() {
		this.enrollmentList();
	}

  onCustom(event: any) {
		if (event.action == 'Edit') {
      localStorage.setItem("isPoints","1");
      this._router.navigate([ '/trip-enrollment-detail/'+ event.data.id]);
		}
	}
  enrollmentList()
  {
    this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/TripEnrollment/CustomerPointListPage?year='+(this.years),
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});
  }
}
